import  firebase  from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

const config = {
  apiKey: "AIzaSyCfqG8pH5nSYx09QEzk3FgRhv2mnNkd9Zk",
  authDomain: "general-supply-portal.firebaseapp.com",
  databaseURL: "https://general-supply-portal.firebaseio.com",
  projectId: "general-supply-portal",
  storageBucket: "general-supply-portal.appspot.com",
  messagingSenderId: "138229788900",
  appId: "1:138229788900:web:9f946da876b42e3e2e3cf1"
}

firebase.initializeApp(config)

const db = firebase.firestore()
const fb = firebase.functions()
const auth = firebase.auth()
const storage = firebase.storage()
const currentUser = auth.currentUser

export {
  db,
  fb,
  auth,
  storage,
  currentUser
}
