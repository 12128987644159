<template>
  <basicLayout>
    <div class="container">
      <div class="card">
        <div class="card-body p-3">
          <h4>Create User</h4>
          <form @submit.prevent="create_user">
            <div class="form-group">
              <label for="Name">Name</label>
              <input
                v-model="user.name"
                type="text"
                class="form-control"
                id="name"
                placeholder="Name"
                required
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input
                v-model="user.email"
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Enter email"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input
                v-model="user.password"
                type="password"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                required
              />
            </div>
            <div v-if="isAdmin" class="form-group">
              <label for="front">Roles</label>
              <v-select
                v-model="user.role"
                :options="roles"
                :selectOnTab="true"
                multiple
              ></v-select>
            </div>
            <button type="submit" class="btn btn-secondary">Save</button>
          </form>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<style>
</style>

<script>
import basicLayout from "@/components/basicLayout";
import { fb } from "@/firebaseConfig";
import { auth } from "@/firebaseConfig.js";
import { getters } from "@/store";

export default {
  name: "createUser",
  data: function () {
    return {
      user: {},
      roles: ["admin", "user"],
    };
  },
  components: {
    basicLayout,
  },
  methods: {
    create_user: function () {
      console.log(this.user);
      const createUser = fb.httpsCallable("createUser");
      createUser(this.user).then(function (result) {
        console.log(result);
      });
      this.$router.push("/users");
    },
    computed: {
      currentUser() {
        return auth.currentUser;
      },
      isAdmin() {
        return getters.isAdmin();
      },
    },
  },
};
</script>