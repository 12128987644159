<template>
  <basicLayout>
    <div class="row">
      <!-- <button @click="products_sync_up_and_down" class="btn btn btn-primary">
      products_sync_up_and_down
    </button> -->
      <button @click="product_sync_bc_to_portal" class="btn btn btn-primary">
        product_sync_bc_to_portal
      </button>
      <button @click="product_sync_fo_to_portal" class="btn btn btn-primary">
        product_sync_fo_to_portal
      </button>
      <button @click="product_sync_portal_to_bc" class="btn btn btn-primary">
        product_sync_portal_to_bc
      </button>
    </div>
    <div class="row">
      <button @click="mailchimp_ping" class="btn btn btn-primary">
        mailchimp_ping
      </button>
    </div>
    <!-- <button @click="products_sync_bc_purge" class="btn btn btn-danger">
      products_sync_bc_purge
    </button> -->
  </basicLayout>
</template>

<script>
import { fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";

// const VENDOR_ID = 2655;

export default {
  name: "admin",
  data() {
    return {};
  },
  components: { basicLayout },

  methods: {
    products_sync_up_and_down() {
      console.log("products_sync_up_and_down");
      const products_sync_up_and_down = fb.httpsCallable(
        "products_sync_up_and_down"
      );
      products_sync_up_and_down().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    product_sync_bc_to_portal() {
      console.log("product_sync_bc_to_portal");
      const product_sync_bc_to_portal = fb.httpsCallable(
        "product_sync_bc_to_portal"
      );
      product_sync_bc_to_portal().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    product_sync_fo_to_portal() {
      console.log("product_sync_fo_to_portal");
      const product_sync_fo_to_portal = fb.httpsCallable(
        "product_sync_fo_to_portal"
      );
      product_sync_fo_to_portal().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    product_sync_portal_to_bc() {
      console.log("product_sync_portal_to_bc");
      const product_sync_portal_to_bc = fb.httpsCallable(
        "product_sync_portal_to_bc"
      );
      product_sync_portal_to_bc().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    products_sync_bc_purge() {
      console.log("products_sync_bc_purge");
      const products_sync_bc_purge = fb.httpsCallable("products_sync_bc_purge");
      products_sync_bc_purge().then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    mailchimp_ping() {
      console.log("mailchimp");
      const mailchimp = fb.httpsCallable("mailchimp_ping");
      mailchimp().then((result) => {
        console.log(JSON.stringify(result));
      });
    },
  },
};
</script>

<style>
</style>