<template>
  <div>
    <canvas ref="myChart" width="500" height="300"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";
import { db } from "@/firebaseConfig";

export default {
  name: "online-sales-chart",
  data() {
    return {
      chart: null,
      orders: [],
    };
  },
  firestore: {
    orders: db
      .collection("bc_orders")
      .orderBy("transaction_date", "desc")
      .limit(3000),
  },
  mounted() {
    this.chart = new Chart(this.$refs.myChart, {
      type: "bar",
      data: {
        labels: [],
        datasets: this.datasets,
      },
      options: {
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "linear",
              time: {
                unit: "day",
                displayFormats: { day: "dd DD MMM" },
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function (value) {
                  return value.toLocaleString("en-NZ", {
                    style: "currency",
                    currency: "NZD",
                  });
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
              });
            },
          },
        },
      },
    });
  },

  watch: {
    datasets: function (newDataSets) {
      if (newDataSets) {
        this.chart.data.datasets = newDataSets;
        this.chart.update();
      }
    },
  },

  computed: {
    datasets() {
      const data = this.orders
        .map((d) => {
          return {
            x: moment.utc(d.transaction_date).local().format("ll"),
            y: d.transaction_total,
          };
        })
        .reduce((result, item) => {
          const existing = result.find((point) => point.x === item.x);
          if (existing) {
            existing.y += item.y;
          } else {
            result.push(item);
          }
          return result;
        }, []);
      return [
        {
          label: "Online Sales",
          backgroundColor: "rgba(40,167,69, 0.75)",
          borderColor: "rgba(40,167,69, 1.0)",
          data: data,
        },
      ];
    },
  },
};
</script>