<template>
  <basicLayout>
    <div class="container p-3">
      <div class="card">
        <div class="card-body p-3">
          <h4>{{ name.name }} ({{ name.code }})</h4>
          <form @submit.prevent="save_name">
            <div class="form-group">
              <label for="Price">Group</label>
              <v-select :options="group_search" v-model="name.group"></v-select>
              <!-- <input
                v-model="name.group"
                type="text"
                class="form-control"
                id="price"
                placeholder="Group Code"
              />-->
            </div>
            <button type="submit" class="btn btn-secondary">Save</button>
          </form>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";

export default {
  name: "RuleEdit",
  data() {
    return {
      name: {},
      groups: [],
    };
  },
  firestore() {
    return {
      name: db.collection("names").doc(this.$route.params.name_code),
      groups: db.collection("groups"),
    };
  },
  components: {
    basicLayout,
  },

  methods: {
    async save_name() {
      console.log(this.name.code);
      await db
        .collection("names")
        .doc(this.name.code)
        .set({ group: this.name.group.id }, { merge: true });

      await db
        .collection("groups")
        .doc(this.name.group.id)
        .set({ name: this.name.group.id }, { merge: true });

      this.$router.push("/customers");
    },
  },
  computed: {
    group_search() {
      return this.groups.map((g) => {
        let group_object = {
          id: g.id,
          label: g.name,
        };
        return group_object;
      });
    },
  },
};
</script>

