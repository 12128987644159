<template>
  <basicLayout>
    <div class="p-2">
      <h5>PRODUCTS</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Code, Name etc."
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
          <div class="btn-toolbar">
            <button
              @click="not_on_fo = !not_on_fo"
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2"
            >
              {{ not_on_fo ? "All" : "Not on FO" }}
            </button>
          </div>
        </div>
      </div>
      <div class="btn-toolbar"></div>

      <hr />
      <div class="row mb-2 mt-2">
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.products.length }}</b>
        </div>
      </div>

      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_by('bc_product_sku')">BC SKU</th>
              <th @click="sort_by('bc_product_name')" class="w-25">BC Name</th>
              <th @click="sort_by('bc_product_id')" class="w-10">BCID</th>
              <th @click="sort_by('is_on_freshoffice')" class="w-5">On FO</th>
              <th @click="sort_by('bc_product_id')" class="w-5">BC Visible</th>
              <th @click="sort_by('default_retail_net')" class="w-10">
                Default Net
              </th>
              <th @click="sort_by('default_retail_net')" class="w-10">
                Default Gross
              </th>
              <th @click="sort_by('default_wholesale')" class="w-10">
                FO Price
              </th>
              <th class="w-10">Actual Margin</th>
              <th @click="sort_by('bc_product_id')" class="w-10">MOQ</th>
              <th @click="sort_by('stock_on_hand')" class="w-10">SOH</th>
              <th @click="sort_by('stock_on_order')" class="w-10">SOO</th>
              <th @click="sort_by('stock_due_date')" class="w-10">Due Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in filtered" :key="product.id">
              <td>{{ product.bc_product_sku }}</td>
              <td>{{ product.bc_product_name }}</td>

              <td>
                <a
                  :href="`https://generalsupply.co.nz/manage/products/${product.bc_product_id}/edit`"
                  target="_blank"
                  >{{ product.bc_product_id }}</a
                >
              </td>
              <td v-if="product.is_on_freshoffice" class="text-success">
                <check-circle-icon
                  size="1.0x"
                  class="custom-class"
                ></check-circle-icon>
              </td>
              <td v-else class="text-danger">
                <x-circle-icon size="1.0x" class="custom-class"></x-circle-icon>
              </td>
              <td v-if="product.bc_product_is_visible" class="text-success">
                <eye-icon size="1.0x" class="custom-class"></eye-icon>
              </td>
              <td v-else class="text-danger">
                <eye-off-icon size="1.0x" class="custom-class"></eye-off-icon>
              </td>
              <td class="text-right">
                {{ product.default_retail_net | currency }}
              </td>
              <td class="text-right">
                {{ (product.default_retail_net * 1.15) | currency }}
              </td>
              <td class="text-right">
                {{ product.default_wholesale | currency }}
              </td>

              <td class="text-right">{{ margin(product) }}%</td>
              <td class="text-right">
                {{ product.moq }}
              </td>
              <td class="text-right">
                {{ product.stock_on_hand }}
              </td>
              <td class="text-right">
                {{ product.stock_on_order }}
              </td>
              <td class="text-right">
                {{ product.stock_due_date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="pages" :pages="pages" v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
import {
  XCircleIcon,
  CheckCircleIcon,
  EyeIcon,
  EyeOffIcon,
} from "vue-feather-icons";

export default {
  name: "products",
  data() {
    return {
      products: [],
      search: "",
      debounce: null,
      sort_column: ["code"],
      sort_order: "asc",
      page_limit: 500,
      selected_page: 1,
      not_on_fo: false,
    };
  },
  components: {
    basicLayout,
    Pagination,
    XCircleIcon,
    CheckCircleIcon,
    EyeIcon,
    EyeOffIcon,
  },
  mixins: [filter_mixins],

  firestore: {
    products: db.collection("products"),
  },
  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },

    filter_by(filter) {
      this.search = filter;
    },

    clear_search() {
      this.search = "";
    },
    margin(product) {
      let m = (product.bc_product_price / product.default_wholesale - 1) * 100;
      return m.toFixed(0);
    },
  },
  computed: {
    filtered() {
      let visible_products = this.products.slice();

      let search = this.search;

      if (this.not_on_fo) {
        visible_products = visible_products.filter(
          (product) => product.is_on_freshoffice === false
        );
      }

      if (this.sort_column) {
        visible_products = orderBy(
          visible_products,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      if (!search) {
        let start = (this.selected_page - 1) * this.page_limit;
        let end = start + this.page_limit;
        visible_products = visible_products.slice(start, end);
      }

      visible_products = visible_products.filter((customer) => {
        const s = Object.values(customer);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      return visible_products;
    },
    pages() {
      return Math.ceil(this.products.length / this.page_limit);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100% !important;
}
</style>
