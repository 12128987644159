// import moment from "moment"
import { starshipit_request } from "./starshipit_request"

export async function starshipit_tracking() {

    try {

        let method = "GET"
            // let last_week = moment()
            .subtract(4, "days")
            .toISOString();
        let url = `orders/search?phrase=GENSUP&limit=2000`;

        let options = {
            url, method
        }

        const order_res = await starshipit_request(options);
        let order_details = order_res.orders;

        return order_details;
    } catch (error) {
        console.log(error);
    }
}