import Vue from 'vue';
import {
  db
} from "@/firebaseConfig";

const admin_roles = ["admin", "superadmin"]

export const state = Vue.observable({
  currentUser: {},
  userProfile: {},
  loading: false
});

export const getters = {
  currentUser: () => state.currentUser,
  userProfile: () => state.userProfile,
  userRole: () => state.userProfile.role,
  isAdmin: () => state.userProfile.role && admin_roles.some(role => state.userProfile.role.includes(role)) || false,
  isLoading: () => state.loading
}

export const mutations = {
  setCurrentUser: (val) => state.currentUser = val,
  setUserProfile: (val) => state.userProfile = val,
  setLoading: (val) => state.loading = val
}

export const actions = {
  fetchCurrentUserProfile(user) {
    db.collection("users").doc(user.uid).get()
      .then(res => {
        const user_profile = res.data()
        mutations.setUserProfile(user_profile);
      })
      .catch(err => {
        console.log(err);
      });
  },
  clearUser() {
    mutations.setUserProfile({});
    mutations.setCurrentUser({});
  },
  setLoading(loadingState) {
    mutations.setLoading(loadingState)
  }
}







// export const store = {
//   state: {
//     currentUser: {
//       email: "alex@alexwebster.com"
//     }
//   },
//   setUser(user) {
//     this.state.currentUser = user;
//   },
//   clearUser() {
//     this.state.currentUser = {};
//   }
// };
