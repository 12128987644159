<template>
  <basicLayout>
    <div class="container">
      <div class="card">
        <div class="card-body p-3">
          <h4>{{ user.name }}</h4>
          <form @submit.prevent="save_user">
            <div class="form-group">
              <label for="Name">Name</label>
              <input
                v-model="user.name"
                type="text"
                class="form-control"
                id="name"
                placeholder="Name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input
                v-model="user.email"
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Enter email"
              />
            </div>
            <div v-if="isAdmin" class="form-group">
              <label for="front">Roles</label>
              <v-select
                v-model="user.role"
                :options="roles"
                :selectOnTab="true"
                multiple
              ></v-select>
              <div class="invalid-feedback">Please select a valid tier.</div>
            </div>

            <button type="submit" class="btn btn-secondary">Save</button>
          </form>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { auth } from "@/firebaseConfig.js";
import { getters } from "@/store";
import basicLayout from "@/components/basicLayout";

export default {
  name: "userProfile",
  data() {
    return {
      user: {},
      roles: ["admin", "user"],
    };
  },
  firestore() {
    return {
      user: db.collection("users").doc(this.$route.params.id),
    };
  },
  components: {
    basicLayout,
  },

  methods: {
    save_user() {
      console.log(this.user.id);
      db.collection("users").doc(this.user.id).set(this.user);
      this.$router.push("/users");
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    isAdmin() {
      return getters.isAdmin();
    },
  },
};
</script>

