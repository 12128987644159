import Vue from 'vue'
import Router from 'vue-router'
import { auth, db } from '@/firebaseConfig'

import Admin from './components/Admin.vue'
import Dashboard from './components/Dashboard.vue'
import Orders from './components/Orders.vue'
import OnlineOrdersChart from './components/OnlineOrdersChart.vue'
import Products from './components/Products.vue'
import Tracking from './components/Tracking.vue'
import Webhooks from './components/Webhooks.vue'
import Users from './components/users.vue'
import Login from './components/Login.vue'
import UserProfile from './components/userProfile.vue'
import UserCreate from './components/userCreate.vue'

import NameEdit from './components/NameEdit.vue'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/online-orders-chart',
    name: 'OnlineOrdersChart',
    component: OnlineOrdersChart,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/name/:name_code',
    name: 'NameEdit',
    component: NameEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: Tracking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    component: Webhooks,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user-create',
    name: 'UserCreate',
    component: UserCreate,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user/:id',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
  ]
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const currentUser = auth.currentUser
  const userProfile = currentUser && await db.collection('users').doc(currentUser.uid).get()
  const isAdmin = userProfile && userProfile.data().role === 'admin'

  if (!requiresAuth) {
    next()
  } else if (requiresAuth && !currentUser) {
    next({
      path: '/login'
    })
  } else if (requiresAuth && currentUser) {
    if (!requiresAdmin) {
      next()
    } else if (requiresAdmin && isAdmin) {
      next()
    } else if (requiresAdmin && !isAdmin) {
      next(false)
    }
  }
})

export default router