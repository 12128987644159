<template>
  <basicLayout>
    <div class="p-2">
      <h5>ONLINE ORDERS</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="ID, Name or Company"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>

          <div class="btn-toolbar">
            <button
              @click="fetch_orders"
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2"
            >
              fetch
            </button>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <div class="tableFixHead">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th class="text-left" scope="col">Date</th>
                <th class="text-left" scope="col">ID</th>
                <th class="text-left" scope="col"></th>
                <!-- <th class="text-left" scope="col"></th> -->
                <th class="text-left" scope="col">Name</th>
                <th class="text-left" scope="col">Company</th>
                <!-- <th class="text-left" scope="col">Status</th> -->
                <th class="text-right" scope="col">Total</th>
                <th class="text-center" scope="col">MW</th>
                <th class="text-center" scope="col">FO</th>
                <th class="text-center" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in filtered" :key="order.id">
                <td class="text-left">
                  {{ order.transaction_date | moment_long }}
                </td>
                <td @dblclick="re_fetch_order(order)" class="text-left">
                  {{ order.id }}
                </td>
                <!-- <td>
                  <v-popover placement="left">
                    <info-icon size="1.2x"></info-icon>
                    <template slot="popover">
                      <OrderPopover :order="order"></OrderPopover>
                    </template>
                  </v-popover>
                </td> -->
                <td class="text-center text-success">
                  <a
                    :href="`https://store-4zlmi5vt53.mybigcommerce.com/manage/orders/search/results?orderTo=${order.id}&orderFrom=${order.id}&channels=1`"
                    target="_blank"
                  >
                    <eye-icon size="1.0x" class="text-success"></eye-icon
                  ></a>
                </td>

                <td class="text-left">
                  {{ order.first_name }} {{ order.last_name }}
                </td>
                <td class="text-left">{{ order.company }}</td>
                <!-- <td class="text-left">{{ order.transaction_status }}</td> -->
                <td class="text-right">
                  {{ order.transaction_total | currency }}
                </td>

                <td
                  v-if="
                    !order.moneyworks_imported && !order.moneyworks_response
                  "
                  class="text-center"
                >
                  <button
                    @click="show_xml(order)"
                    type="button"
                    class="btn btn-x-sm btn-warning"
                    title="Failed to import to MoneyWorks"
                  >
                    MW
                  </button>
                  &nbsp;
                </td>
                <td
                  v-else-if="
                    order.moneyworks_imported && order.moneyworks_response
                  "
                  class="text-center"
                >
                  <button
                    class="btn btn-x-sm btn-success"
                    title="Succesfully imported to MoneyWorks"
                  >
                    MW
                  </button>
                </td>
                <td
                  v-else-if="
                    !order.moneyworks_imported && order.moneyworks_response
                  "
                  class="text-center"
                >
                  <button
                    @click="show_xml(order)"
                    type="button"
                    class="btn btn-x-sm btn-danger"
                    title="Failed to import"
                  >
                    MW
                  </button>
                  &nbsp;
                </td>

                <td
                  v-if="
                    !order.freshoffice_imported && !order.freshoffice_response
                  "
                  class="text-center"
                >
                  <button
                    class="btn btn-x-sm btn-warning"
                    title="Failed to import to MoneyWorks"
                  >
                    FO
                  </button>
                  &nbsp;
                </td>
                <td
                  v-else-if="
                    order.freshoffice_imported && order.freshoffice_json
                  "
                  class="text-center"
                >
                  <button
                    class="btn btn-x-sm btn-success"
                    title="Succesfully imported to MoneyWorks"
                  >
                    FO
                  </button>
                </td>
                <td
                  v-else-if="
                    !order.freshoffice_imported && order.freshoffice_json
                  "
                  class="text-center"
                >
                  <button
                    type="button"
                    class="btn btn-x-sm btn-danger"
                    title="Failed to import"
                  >
                    FO
                  </button>
                  &nbsp;
                </td>
                <td class="text-center">
                  <button
                    v-if="order.tracking_urls"
                    @click="track(order)"
                    class="btn btn-x-sm btn-outline-secondary"
                    :title="`Track ${order.tracking_urls[0].tracking_number}`"
                  >
                    <compass-icon
                      size="1.0x"
                      class="custom-class"
                    ></compass-icon>
                  </button>
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="show_xml_modal">
      <div class="modal fade show modal-mask" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ selected_order.id }}</h5>

              <button
                @click="show_xml_modal = false"
                type="button"
                class="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ selected_order.moneyworks_json }}</p>
              <textarea
                v-model="selected_order.moneyworks_xml"
                spellcheck="false"
              ></textarea>
            </div>
            <div class="modal-footer">
              <button
                @click="show_xml_modal = false"
                type="button"
                class="btn btn-secondary"
              >
                Close
              </button>
              <button
                @click="resubmit_xml"
                type="button"
                class="btn btn-primary"
              >
                Resubmit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="show_json_modal">
      <div class="modal fade show modal-mask" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ selected_order.id }}</h5>

              <button
                @click="show_json_modal = false"
                type="button"
                class="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ selected_order.moneyworks_json }}</p>
              <textarea
                v-model="selected_order.freshoffice_json"
                spellcheck="false"
              ></textarea>
            </div>
            <div class="modal-footer">
              <button
                @click="show_json_modal = false"
                type="button"
                class="btn btn-secondary"
              >
                Close
              </button>
              <button
                @click="resubmit_json"
                type="button"
                class="btn btn-primary"
              >
                Resubmit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import moment from "moment";
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
// import OrderPopover from "@/components/OrderPopover";
import { CompassIcon, EyeIcon } from "vue-feather-icons";

export default {
  name: "orders",
  data() {
    return {
      orders: [],
      search: "",
      show_xml_modal: false,
      show_json_modal: false,
      selected_order: {},
    };
  },
  components: { basicLayout, CompassIcon, EyeIcon },

  firestore() {
    return {
      orders: db
        .collection("bc_orders")
        .orderBy("transaction_date", "desc")
        .limit(200),
    };
  },
  methods: {
    clear_search() {
      this.search = "";
    },
    show_xml(order) {
      this.selected_order = order;
      this.show_xml_modal = true;
      console.log(this.selected_order);
    },
    resubmit_xml() {
      console.log(this.selected_order);
      const mw_xml_resubmit = fb.httpsCallable("mw_xml_resubmit");
      mw_xml_resubmit(this.selected_order).then((result) => {
        console.log(JSON.stringify(result));
      });
      this.show_xml_modal = false;
    },

    show_json(order) {
      this.selected_order = order;
      this.show_json_modal = true;
      console.log(this.selected_order);
    },
    resubmit_json() {
      console.log(this.selected_order);
      const mw_json_resubmit = fb.httpsCallable("freshoffice_resubmit");
      mw_json_resubmit(this.selected_order).then((result) => {
        console.log(JSON.stringify(result));
      });
      this.show_json_modal = false;
    },

    fetch_orders() {
      const bigcommerce_fetch_all = fb.httpsCallable("bigcommerce_fetch_all");
      bigcommerce_fetch_all().then((result) => {
        console.log(JSON.stringify(result));
      });
    },
    re_fetch_order(order) {
      const bigcommerce_fetch_again = fb.httpsCallable(
        "bigcommerce_fetch_again"
      );
      bigcommerce_fetch_again(order).then((result) => {
        console.log(JSON.stringify(result));
      });
    },
    track(order) {
      const tracking_url = order.tracking_urls[0].tracking_url;
      window.open(tracking_url, "_blank");
    },
  },
  computed: {
    filtered: function () {
      return this.orders.filter((order) => {
        const s = order.id + order.first_name + order.last_name + order.company;
        const su = s.toUpperCase();
        return su.match(this.search.toUpperCase());
      });
    },
  },
  filters: {
    moment_short: function (date) {
      return moment.utc(date).local().format("ll");
    },
    moment_long: function (date) {
      return moment.utc(date).local().format("LLLL");
    },
    currency: function (number) {
      return (
        number &&
        number.toLocaleString("en-NZ", { style: "currency", currency: "NZD" })
      );
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  padding-right: 17px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.container {
  width: 100% !important;
}

.modal {
  font-size: 0.9rem;
}
textarea {
  width: 100%;
  height: 60vh;
}
</style>