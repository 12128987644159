<template>
  <basicLayout>
    <!-- https://bootstrapious.com/p/bootstrap-sidebar -->
    <h5>DASHBOARD</h5>
    <div>
      <div class="row m-1">
        <div class="col-12">
          <Ticker />
        </div>
      </div>
      <div class="row m-1">
        <div class="col-12">
          <div class="card-group">
            <Logs></Logs>
            <OrdersLog></OrdersLog>
          </div>
        </div>
      </div>
      <div class="row m-1">
        <div class="col-12">
          <div class="card-deck mb-3">
            <div class="card mb-4 shadow-sm">
              <div class="card-header">
                <h5 class="my-0 font-weight-normal">Online Sales</h5>
              </div>
              <div class="card-body">
                <onlineorderschart></onlineorderschart>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5">
          <div class="card mb-4 shadow-sm">
            <div class="card-header">
              <h5 class="my-0 font-weight-normal">Bulk Product Uploads</h5>
            </div>
            <div class="card-body">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  @change="upload"
                />
                <label class="custom-file-label" for="customFile"
                  >Choose file...</label
                >
              </div>
            </div>
            <!-- <button @click="runTables" type="button" class="btn btn-primary">
              runTables
            </button>
            <button
              @click="products_hide"
              type="button"
              class="btn btn-primary"
            >
              products_hide
            </button> -->
            <!-- <button @click="bc_prices" type="button" class="btn btn-primary">bc_prices</button> -->
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import Logs from "@/components/Logs";
import OrdersLog from "@/components/OrdersLog";
import Ticker from "@/components/Ticker";
import onlineorderschart from "@/components/OnlineOrdersChart";

// import "jsprintmanager";

export default {
  name: "dashboard",
  data() {
    return {
      orders: [],
      logs: [],
      log_type: "all",
      printers: [],
    };
  },
  components: { basicLayout, Logs, OrdersLog, Ticker, onlineorderschart },

  methods: {
    upload(event) {
      console.log("uploading...");
      const file = event.target.files[0];
      const storageRef = storage.ref(file.name);
      storageRef.put(file);
    },

    runTables() {
      console.log("bc price rules");
      const runTables = fb.httpsCallable("runTables");
      runTables().then((result) => {
        console.log(JSON.stringify(result));
      });
    },
    products_hide() {
      console.log("bc price rules");
      const products_hide = fb.httpsCallable("products_hide");
      products_hide().then((result) => {
        console.log(JSON.stringify(result));
      });
    },
    log_class(log) {
      if (log.type == "info") return "text-secondary";
      if (log.type == "error") return "text-danger";
      if (log.type == "moneyworks") return "text-primary";
    },
  },
  computed: {},
};
</script>
<style scoped></style>