<template>
  <div class="login text-center">
    <div class="form-signin">
      <div class="p-3">
        <img src="../assets/gensup_logo.png" width="auto" height="70px" alt />
      </div>
      <form v-if="!reset" @submit.prevent="login">
        <h1 class="h3 mb-3 font-weight-normal">Please Sign In</h1>
        <label for="inputEmail" class="sr-only">Email address</label>
        <input
          v-model="email"
          type="email"
          id="inputEmail"
          class="form-control"
          placeholder="Email address"
          required
          autofocus
        />
        <label for="inputPassword" class="sr-only">Password</label>
        <input
          v-model="password"
          type="password"
          id="inputPassword"
          class="form-control"
          placeholder="Password"
          required
        />
        <button
          class="btn btn-lg btn-outline-secondary btn-block"
          type="submit"
        >
          Sign in
        </button>
        <a href="#" @click="forgot">Forgot Password?</a>
      </form>

      <form v-else @submit.prevent="reset_password">
        <h1 class="h3 mb-3 font-weight-normal">Please Enter Login Email</h1>
        <label for="inputEmail" class="sr-only">Email address</label>

        <input
          v-model="email"
          type="email"
          id="inputEmail"
          class="form-control"
          style="margin-bottom: 10px"
          placeholder="Email address"
          required
          autofocus
        />
        <button
          class="btn btn-lg btn-outline-secondary btn-block"
          type="submit"
        >
          Reset Password
        </button>
        <a href="#" @click="forgot">Back to Login</a>
      </form>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebaseConfig";
import { mutations, actions } from "@/store";
//import BasicLayout from "@/components/BasicLayout";
export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
      reset: false,
    };
  },

  methods: {
    login: function () {
      auth.signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          // console.log(auth.currentUser);
          console.log(user.uid);
          mutations.setCurrentUser(auth.currentUser);
          actions.fetchCurrentUserProfile(auth.currentUser);
          this.$router.push("/");
        },
        (err) => {
          alert(err.message);
        }
      );
    },
    forgot: function () {
      console.log(this.reset);
      this.reset = !this.reset;
    },
    reset_password: function () {
      auth.sendPasswordResetEmail(this.email).then(
        (user) => {
          this.reset = false;
          return user;
        },
        (err) => {
          alert(err.message);
          return err;
        }
      );
    },
  },
};
</script>

<style>
.login {
  /* color: #fff; */
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #e2e4e3;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>