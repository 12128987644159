<template>
  <basicLayout>
    <div class="p-2">
      <h5>WEBHOOKS</h5>
      <div class="card">
        <div class="card-body p-3">
          <h4>Create Webhook</h4>
          <form @submit.prevent="createWebhook">
            <div class="form-group">
              <label for="Name">Scope</label>
              <input
                v-model="webhook.scope"
                type="text"
                class="form-control"
                placeholder="e.g. store/order/*"
                required
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Endpoint</label>
              <input
                v-model="webhook.destination"
                type="text"
                class="form-control"
                placeholder="e.g. https://portal.generalsupply.co.nz/webhooks"
                required
              />
            </div>
            <button type="submit" class="btn btn-secondary">Create</button>
          </form>
        </div>
      </div>

      <div class="mt-3">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th class="text-left" scope="col">ID</th>
              <th class="text-left" scope="col">Scope</th>
              <th class="text-left" scope="col">Destination</th>
              <th class="text-left" scope="col">Active</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="webhook in webhooks" :key="webhook.id">
            <tr>
              <td class="text-left">{{ webhook.id }}</td>
              <td class="text-left">{{ webhook.scope }}</td>
              <td class="text-left">{{ webhook.destination }}</td>
              <td class="text-left">{{ webhook.is_active }}</td>

              <td class="text-center" title="reset">
                <a @click="resetWebhook(webhook.id)">&#8635;</a>
              </td>
              <td class="text-center" title="delete">
                <a @click="deleteWebhook(webhook.id)">&#10005;</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";

export default {
  name: "webhooks",
  data() {
    return {
      webhooks: [],
      webhook: {},
    };
  },
  components: { basicLayout },
  created() {
    this.getWebhooks();
  },
  methods: {
    getWebhooks() {
      const getWebhooks = fb.httpsCallable("bc_webhook_request");
      const data = {
        method: "GET",
      };
      getWebhooks(data).then((result) => {
        console.log(JSON.stringify(result));
        this.webhooks = result.data;
      });
    },

    deleteWebhook(id) {
      const deleteWebhook = fb.httpsCallable("bc_webhook_request");
      const data = {
        method: "DELETE",
        id: id,
      };
      deleteWebhook(data).then((result) => {
        console.log(JSON.stringify(result));
        this.getWebhooks();
      });
    },
    resetWebhook(id) {
      const resetWebhook = fb.httpsCallable("bc_webhook_request");
      const data = {
        method: "PUT",
        id: id,
        data: { is_active: true },
      };
      resetWebhook(data).then((result) => {
        console.log(JSON.stringify(result));
        this.getWebhooks();
      });
    },
    createWebhook() {
      const createWebhook = fb.httpsCallable("bc_webhook_request");

      console.log("webhook: " + this.webhook);
      this.webhook.is_active = true;
      const data = {
        method: "POST",
        data: this.webhook,
      };
      createWebhook(data).then((result) => {
        console.log(JSON.stringify(result));
        this.getWebhooks();
      });
    },
  },
  computed: {},
};
</script>