<template>
  <div class="card-deck mb-3">
    <div class="card mb-4 shadow-sm">
      <div class="card-body">
        <h6>MONEYWORKS CRON_ROBOT LAST RAN:</h6>
        <heart-icon size="1.5x" class="heart" :class="heart_colour"></heart-icon
        >&nbsp;
        <span v-show="time_now"
          >{{ ticker_time_since }} ({{ ticker_time | moment_short }})</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import moment from "moment";
import { fb, db } from "@/firebaseConfig";
import filter_mixins from "@/mixins/filter_mixins";
import { HeartIcon } from "vue-feather-icons";

export default {
  name: "ticker",
  data() {
    return {
      ticker: {},
      time_now: null,
    };
  },
  components: {
    HeartIcon,
  },
  mixins: [filter_mixins],
  firestore: {
    ticker: db.collection("ticker").doc("ticker"),
  },
  methods: {
    ticker_check() {
      console.log("ticker_check");
      const ticker_check = fb.httpsCallable("ticker_check");
      ticker_check().then((result) => {
        console.log(JSON.stringify(result));
      });
    },
  },
  computed: {
    ticker_time() {
      return (this.ticker && this.ticker.time) || null;
    },
    ticker_time_since() {
      return (
        this.ticker_time &&
        moment(this.ticker_time.toDate()).from(this.time_now)
      );
    },
    ticker_time_diff() {
      return (
        this.ticker_time &&
        moment(this.ticker_time.toDate()).diff(this.time_now, "hours")
      );
    },
    heart_colour() {
      return Math.abs(this.ticker_time_diff) < 2
        ? "text-success"
        : "text-danger";
    },
  },
  created() {
    setInterval(() => {
      if (this.ticker_time) {
        this.time_now = moment(new Date());
      }
    }, 1000);
  },
};
</script>

<style scoped>
table {
  font-size: 0.75rem;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.075);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.heart {
  animation: heartbeat 2s infinite;
}
</style>

